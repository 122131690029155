.popup-overlay {
  background: rgba(0, 0, 0, 0.8);
}

.popup-dot-content {
  z-index: 1;
}

.popup-close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
}